import React, { useEffect, useReducer } from "react";
import { Link, useStaticQuery } from "gatsby";
import reducers from "../components/reducers";
import { useStore } from "./store";

const Navbar = ({ location, sortedYears }) => {
  const [open, setOpen] = React.useState(false);
  const [showYears, expandYears] = React.useState(false);
  const [hover, setHover] = React.useState("");
  const [years, setYears] = React.useState([]);
  const isHomepage = location === "/";
  const isVita = location === "vita";
  const isWorksPage = location === "works";
  const isContact = location === "contact";
  const { state, dispatch } = useStore();
  
  const setSelectedYear = (year) => dispatch({ type: "set_year", data: year });

  useEffect(() => {
    const years = JSON.parse(sessionStorage.getItem("years"));
    setYears(years || sortedYears);
  }, []);
  return (
    <header className="header fixed bg-white z-10">
      <div className="grid grid-cols-20 h-10 items-center">
        {/* mobile nav */}
        <nav className="grid w-full justify-end md:hidden z-100 col-span-20 relative">
          <svg
            onClick={() => setOpen(!open)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <div
            className={`${
              open
                ? "fixed h-auto w-full top-10 bg-white z-10 left-0"
                : "hidden"
            }`}
          >
            <ul>
              <Link
                to="/"
                className="h-14 text-xs border-solid divide-gray-500 border-b border-t text-center flex items-center justify-center"
              >
                home
              </Link>
              <div
                onClick={() => expandYears(!showYears)}
                className="h-auto text-xs h-14 border-solid divide-gray-500 border-b text-center flex items-center justify-center"
              >
                works
                <div className="h-6 w-6 ml-2 absolute right-5">
                  {showYears ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              </div>
              {showYears && (
                <div>
                  {years?.map((year) => (
                    <div
                      onClick={() => setSelectedYear(year)}
                      key={year}
                      className="cursor-pointer h-12 text-xs border-solid divide-gray-500 border-b text-center flex items-center justify-center"
                    >
                      {year}
                    </div>
                  ))}
                </div>
              )}

              <Link
                to="/vita"
                className="h-14 text-xs border-solid divide-gray-500 border-b text-center flex items-center justify-center"
              >
                vita
              </Link>
              <Link
                to="/contact"
                className="h-14 text-xs border-solid divide-gray-500 border-b text-center flex items-center justify-center"
              >
                contact
              </Link>
            </ul>
          </div>
        </nav>
        {/* web nav */}
        <nav className="md:grid col-span-12 grid-cols-3 md:grid-cols-12 hidden">
          <Link
            onMouseEnter={() => setHover("home")}
            onMouseLeave={() => setHover("")}
            className="col-span-1 text-xs uppercase"
            activeClassName=""
            to="/"
          >
            {isHomepage || hover === "home" ? "home" : "h"}
          </Link>

          <Link
            onMouseEnter={() => setHover("works")}
            onMouseLeave={() => setHover("")}
            className="col-span-1 text-xs uppercase"
            activeClassName=""
            to="/works"
          >
            {isWorksPage || hover === "works" ? "works" : "w"}
          </Link>

          <Link
            className="col-span-1 text-xs uppercase"
            onMouseEnter={() => setHover("vita")}
            onMouseOut={() => setHover("")}
            to="/vita"
          >
            {isVita || hover === "vita" ? "vita" : "v"}
          </Link>

          <Link
            className="col-span-1 text-xs uppercase"
            to="/contact"
            onMouseEnter={() => setHover("contact")}
            onMouseOut={() => setHover("")}
          >
            {isContact || hover === "contact" ? "contact" : "c"}
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
