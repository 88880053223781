import React, { useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import Dsgvo from "./dsgvo";


const TemplateWrapper = ({ children, location, sortedYears }) => {
  const { title, description } = useSiteMetadata();
  const [showImpress, setImpress] = useState(false);
  const [showPrivacy, setPrivacy] = useState(false);
 

  return (
    
      <div className="px-12 h-screen overflow-auto w-full">
        <Helmet>
          <html lang="en" />
          <title> {title} </title>
          <meta name="description" content={description} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix("/")}img/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-32x32.png`}
            sizes="32x32"
          />
          ^
          <link
            rel="icon"
            type="image/png"
            href={`${withPrefix("/")}img/favicon-16x16.png`}
            sizes="16x16"
          />
          <link
            rel="mask-icon"
            href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
            color="#ff4400"
          />
          <meta name="theme-color" content="#fff" />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content={title} />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={`${withPrefix("/")}img/og-image.jpg`}
          />
        </Helmet>
        <Navbar location={location} sortedYears={sortedYears} />
        <main
          onClick={() => {
            setImpress(false);
            setPrivacy(false);
          }}
          className={`grid pt-16 ${showImpress || showPrivacy ? "blur" : ""}`}
        >
          {children}
        </main>
        <footer className={`px-12 flex flex-row justify-start p-2`}>
          <p
            onClick={() => setImpress(true)}
            className="mr-2 text-gray-500 text-sm cursor-pointer"
          >
            Impressum
          </p>
          <p
            onClick={() => setPrivacy(true)}
            className="mr-2 text-gray-500 text-sm cursor-pointer"
          >
            Datenschutz
          </p>
        </footer>
        <div
          onClick={() => setImpress(false)}
          className={`z-50 flex items-center justify-center fixed bottom-0 left-0 top-0 right-0 ${
            showImpress ? "block" : "hidden"
          }`}
        >
          <div className="flex flex-col bg-gray-100 w-auto h-auto overflow-auto p-10">
            Impress:
            <br />
            <p> Hiutung Ching</p>
            <p>Sommerstraße 24</p>
            <p>81543 München</p>
            <p>lookingthesky2002@gmail.com</p>
          </div>
        </div>
        <div
          onClick={() => setPrivacy(false)}
          className={`z-50 flex items-center justify-center fixed bottom-0 left-0 top-0 right-0 ${
            showPrivacy ? "block" : "hidden"
          }`}
        >
          <div className="flex bg-gray-100 w-9/12 h-4/6 overflow-auto">
            <Dsgvo />
          </div>
        </div>
      </div>
  );
};

export default TemplateWrapper;
