const initialState = { selectedYear: null };

function reducer(state, action) {
  switch (action.type) {
    case "set_year":
      return {
        ...state,
        selectedYear: action.data,
      };

    default:
      return {
        ...state,
        selectedYear: null,
      };
  }
}

export default {
  reducer,
  initialState,
};
